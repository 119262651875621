
import axios from 'axios';
export default {
    
    async createPackedItems(params)  {
        return await axios.post(`packed_items/create` , params)
    },
    async createPackedItemsList(params)  {
        return await axios.post(`packed_items/create/list` , params)
    },
    async updatePackedItemsColumn(column , value , data)  {
        return await axios.put(`packed_items/update_list?${column}=${value}` , data)
    },
    async deletePackedItemsList(list)  {
        return await axios.delete(`packed_items/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportByPackedOrderId(packed_order_id)  {
        return await axios.get(`packed_items/report_by_packed_order_id/${packed_order_id}`)
    },

    async reportPackedItems(column , value)  {
        return await axios.get(`packed_items/report?${column}=${value}`)
    },
    async getAllPackedItems()  {
        return await axios.get(`packed_items/all`)
    },
    async getOnePackedItems(packed_item_id)  {
        return await axios.get(`packed_items/all/${packed_item_id}`)
    },
    async getPackedItemsByColumn(column , value)  {
        return await axios.get(`packed_items/filter?column=${column}&value=${value}`)
    },
    async deletePackedItems(packed_item_id)  {
        return await axios.delete(`packed_items/delete/${packed_item_id}`)
    },
    async updatePackedItems(packed_item_id , params)  {
        return await axios.put(`packed_items/update/${packed_item_id}` , params)
    }
}

import axios from 'axios';
export default {
    
    async createPackedOrders(params)  {
        return await axios.post(`packed_orders/create` , params)
    },
    async createPackedOrdersList(params)  {
        return await axios.post(`packed_orders/create/list` , params)
    },
    async updatePackedOrdersColumn(column , value , data)  {
        return await axios.put(`packed_orders/update_list?${column}=${value}` , data)
    },
    async deletePackedOrdersList(list)  {
        return await axios.delete(`packed_orders/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportPackedOrders(column , value)  {
        return await axios.get(`packed_orders/report?${column}=${value}`)
    },
    async getAllPackedOrders()  {
        return await axios.get(`packed_orders/all`)
    },
    async searchPackedOrders(params) {
        return await axios.post(`packed_orders/search`, params)
    },
    async getOnePackedOrders(packed_order_id)  {
        return await axios.get(`packed_orders/all/${packed_order_id}`)
    },
    async getPackedOrdersByColumn(column , value)  {
        return await axios.get(`packed_orders/filter?column=${column}&value=${value}`)
    },
    async deletePackedOrders(packed_order_id)  {
        return await axios.delete(`packed_orders/delete/${packed_order_id}`)
    },
    async updatePackedOrders(packed_order_id , params)  {
        return await axios.put(`packed_orders/update/${packed_order_id}` , params)
    }
}